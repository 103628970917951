<template>
  <BModal
    id="historyHold"
    ref="historyHold"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    :centered="true"
  >
    <div class="mt-2 mb-1 text-center text-black">
      <div class="text-black text-20-bold">
        Detail Review Akun
      </div>
    </div>
    <p
      class="text-black text-center mb-3"
    >
      Berikut merupakan detail review dari akun user
    </p>
    <div>
      <div>
        <label for="notes">
          Catatan :
        </label>
        <textarea
          id="notes"
          v-model="dataHold.latest_ban.note"
          disabled
          class="form-control"
          rows="4"
        />
      </div>
      <div class="my-1">
        <label for="attach">
          File Bukti :
        </label>
        <div
          id="attach"
          class="d-flex text-end my-1 mb-2"
        >
          <div
            v-for="(item, index) in dataHold.latest_ban.attachments"
            :key="index"
            type="button"
            class="ml-1"
            @click="previewFile(item.url)"
          >
            <BImg
              :src="item.url"
              width="60"
              class="pointer h-16"
            />
            <small>{{ getName(item.filename) }}</small>
          </div>
        </div>
      </div>
      <div class="my-1 text-black">
        <label for="history">
          Riwayat Hold :
        </label>
        <div
          id="history"
          class="overflow-y-auto h-40"
        >
          <div
            v-for="(item, index) in dataHold.previous_bans"
            :key="index"
            class="border border-2 p-[8px] "
          >
            <div>
              <span class="mr-1">{{ index+1 }}.</span>
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center mb-2">
      <BButton
        variant="primary"
        class="w-full"
        @click="$bvModal.hide('historyHold')"
      >
        Tutup
      </BButton>
    </div>
  </BModal>
</template>

<script>
import {
  BModal, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BModal, BButton,
  },
  props: {
    dataHold: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getName(value) {
      const result = `...${value.slice(-10)}`
      return result
    },
    previewFile(value) {
      window.open(value, '_blank')
    },
  },
}
</script>
