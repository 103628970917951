/* eslint-disable import/prefer-default-export */
export const tableConfig = [
  {
    key: 'full_name',
    label: 'Nama',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: '600',
    },
    tdClass: 'text-black',
    class: 'align-middle text-black',
  },
  {
    key: 'join_date',
    label: 'Tanggal Join',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: '600',
    },
    tdClass: 'text-black',
    class: 'align-middle text-black',
  },
  {
    key: 'last_update',
    label: 'Last Update',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: '600',
    },
    tdClass: 'text-black',
    class: 'align-middle text-black',
  },
  {
    key: 'expedition_ratio',
    label: 'Rasio Ekspedisi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: '600',
    },
    tdClass: 'text-black',
    class: 'align-middle text-black',
  },
  {
    key: 'payment_method_ratio',
    label: 'Rasio Payment',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: '600',
    },
    tdClass: 'text-black',
    class: 'align-middle text-black',
  },
  {
    key: 'main_warehouse',
    label: 'Gudang Utama',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: '600',
    },
    tdClass: 'text-black',
    class: 'align-middle text-black',
  },
  {
    key: 'product_ratio',
    label: 'Rasio Produk',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: '600',
    },
    tdClass: 'text-black',
    class: 'align-middle text-black',
  },
  {
    key: 'problem_ratio',
    label: 'Rasio Problem',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: '600',
    },
    tdClass: 'text-black',
    class: 'align-middle text-black',
  },
  {
    key: 'retur_percentage',
    label: 'Retur & Saldo',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: '600',
    },
    tdClass: 'text-black',
    class: 'align-middle text-black',
  },
  {
    key: 'action',
    label: 'Action',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '13px',
      fontWeight: '600',
    },
    tdClass: 'text-black',
    class: 'align-middle text-black',
  },
]

export const statusOptions = [
  {
    label: 'Semua',
    value: 0,
  },
  {
    label: 'Hold',
    value: 2,
  },
  {
    label: 'Safe',
    value: 3,
  },
  {
    label: 'Fake',
    value: 1,
  },
]
