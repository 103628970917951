<template>
  <BModal
    id="modalFakeUser"
    ref="modalFakeUser"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    hide-footer
    hide-header
    size="md"
    :centered="true"
  >
    <div class="my-2">
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/popup-warning.png"
        alt="Komerce"
        class="m-auto"
      >
    </div>
    <p
      v-if="status === 1"
      class="text-black text-center mt-2 mb-3"
    >
      Kamu yakin mau mengkategorikan user ini Fake? Mereka akan keblock aksesnya
      dari sistem Komerce
    </p>
    <p
      v-if="status === 2"
      class="text-black text-center mt-2 mb-3"
    >
      Kamu yakin mau membatasi akses user ini?
      Mereka akan logout secara otomatis dan tidak akan bisa login
    </p>
    <p
      v-if="status === 3"
      class="text-black text-center mt-2 mb-3"
    >
      Kamu yakin mau mengembalikan akses user ini?
      Mereka akan login lagi di sistem komerce
    </p>
    <div v-if="status === 2">
      <div>
        <label for="notes">
          Catatan :
        </label>
        <textarea
          id="notes"
          v-model="notes"
          class="form-control"
          rows="4"
          maxlength="250"
        />
        <div class="d-flex justify-between">
          <small v-if="notes.length < 9 && notes.length !== 0" class="text-danger">Minimal 9 karakter</small>
          <small class="ml-auto">{{ notes.length }}/250</small>
        </div>
      </div>
      <div class="my-1">
        <label for="attach">
          File Bukti :
        </label>
        <b-input-group>
          <b-input-group-append
            class="d-flex justify-between align-items-center w-100 py-[7px] px-1"
            style="
                    border-width: 1px;
                    border-radius: 5px;
                    border-color: #cfcfcf;
                  "
            @click="$refs.attach.click()"
          >
            <span class="text-muted">Upload File</span>
            <span class="text-primary border-l-2 pl-1">Upload</span>
          </b-input-group-append>
          <input
            id="attach"
            ref="attach"
            type="file"
            accept="image/jpeg, image/png, image/jpg"
            multiple
            hidden
            class="form-control"
            @input="chooseImage($event)"
          >
        </b-input-group>
        <small
          v-if="fileSizeExceeded"
          class="text-primary"
        >
          Ukuran file maksimal 2mb
        </small>
        <small
          v-if="maxFiles"
          class="text-primary"
        >
          Maksimal 3 File
        </small>
      </div>
      <div class="d-flex text-end my-1 mb-2">
        <div
          v-for="(item, index) in fileList"
          :key="index"
          class="ml-1"
        >
          <BButton
            variant="light"
            size="sm"
            class="btn-icon p-0 -mb-5 position-relative z-10"
            @click="removeFile(index)"
          >
            <BImg
              src="https://storage.googleapis.com/komerce/assets/icons/close-circle.svg"
              width="16"
              height="10"
            />
          </BButton>
          <BImg
            :src="previewFile(item)"
            width="60"
            class="pointer h-16"
          />
          <small>{{ getName(item) }}</small>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center mb-2">
      <BButton
        variant="outline-primary"
        class="mr-1 w-full"
        @click="closeModal"
      >
        Kembali
      </BButton>
      <BButton
        :variant="status === 2 && (notes.length < 9 || fileList.length === 0) ? 'secondary' : 'primary'"
        class="w-full"
        :disabled="status === 2 ? notes.length < 9 || fileList.length === 0 : false"
        @click="onFinish"
      >
        <BSpinner
          v-if="isLoading"
          small
        />
        Terapkan
      </BButton>
    </div>
  </BModal>
</template>

<script>
import {
  BModal, BButton, BSpinner,
} from 'bootstrap-vue'
import { newAxiosIns } from '@/libs/axios'

export default {
  components: {
    BModal, BButton, BSpinner,
  },
  props: {
    partnerId: {
      type: Number,
      required: true,
    },
    status: {
      type: Number,
      required: true,
    },
    getData: {
      type: Function,
      required: true,
    },
    getDataCard: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      notes: '',
      fileList: [],
      fileSizeExceeded: false,
      maxFiles: false,
    }
  },
  methods: {
    async onFinish() {
      this.isLoading = true
      const url = '/komship/api/v1/admin/fake-users'
      let status = 'unhold'
      if (this.status === 1) {
        status = 'fake'
      } else if (this.status === 2) {
        status = 'hold'
      }
      const formData = new FormData()
      formData.append('partner_id', this.partnerId)
      formData.append('status', status)

      if (this.status === 2) {
        formData.append('note', this.notes)
        this.fileList.forEach((fileObj, index) => {
          formData.append('attachments', fileObj.file)
        })
      }
      await newAxiosIns.post(url, formData)
        .then(() => {
          this.isLoading = false
          this.$toast_success({ message: `"${status}" user berhasil` })
          this.getData()
          this.getDataCard()
          this.closeModal()
        })
        .catch(err => {
          this.$toast_error({ message: `"${status}" user gagal` })
          this.isLoading = false
        })
    },
    chooseImage($event) {
      const files = Array.from($event.target.files)

      files.forEach(file => {
        if (this.fileList.length < 3) {
          this.maxFiles = false
          if (
            file.type === 'image/jpeg'
        || file.type === 'image/jpg'
        || file.type === 'image/png'
          ) {
            if (file.size < 2000000) {
              this.fileSizeExceeded = false
              this.fileList.push({ file })
            } else {
              this.fileSizeExceeded = true
            }
          }
        } else {
          this.maxFiles = true
        }
      })
    },
    previewFile(file) {
      return URL.createObjectURL(file.file)
    },
    removeFile(index) {
      this.fileList.splice(index, 1)
    },
    getName(value) {
      const result = `...${value.file.name.slice(-10)}`
      return result
    },
    closeModal() {
      this.fileList = []
      this.notes = ''
      this.fileSizeExceeded = false
      this.maxFiles = false
      this.$bvModal.hide('modalFakeUser')
    },
  },
}
</script>
